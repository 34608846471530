import { graphql, useStaticQuery } from "gatsby";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import React, { useEffect, useState } from "react";
import {
	Accordion,
	Card,
	Container,
	useAccordionButton,
} from "react-bootstrap";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { Helmet } from "react-helmet";
import CtaBanner from "../components/cta-banner";
import Layout from "../components/layout";

const JargonBusterPage = () => {
	const data = useStaticQuery(graphql`
		query {
			site {
				siteMetadata {
					siteUrl
				}
			}
			pageData: wpPage(slug: { eq: "jargon-busters" }) {
				seoFields {
					localBusinessSchema
					metaDescription
					opengraphDescription
					opengraphTitle
					title
					image {
						node {
							altText
							sourceUrl
							mediaDetails {
								height
								width
							}
						}
					}
				}
				jargonBustersFields {
					jargonBustersBanner {
						jargonBustersHeading
						jargonBustersContent
						selectJargonBusters {
							nodes {
								... on WpJargonBuster {
									id
									jargonBustersPostFields {
										jargonBusterHeading
										jargonBusterContent
									}
									slug
								}
							}
						}
					}
					jargonBustersCta {
						jargonBustersCtaHeading
						jargonBustersCtaContent
						jargonBustersCtaButton {
							title
							target
							url
						}
						jargonBustersCtaBackgroundImage {
							node {
								altText
								sourceUrl
							}
						}
					}
				}
			}
		}
	`);

	const siteUrl = data.site.siteMetadata.siteUrl;
	const {
		pageData: { seoFields, jargonBustersFields },
	} = data;
	const [activeKey, setActiveKey] = useState(null);
	const [jargonBusters, setJargonBusters] = useState([]);
	const [searchText, setSearchText] = useState("");

	useEffect(() => {
		setJargonBusters(
			jargonBustersFields.jargonBustersBanner.selectJargonBusters.nodes
		);
	}, [jargonBustersFields]);

	// Search as you type implementation
	const handleSearchChange = (e) => {
		const searchText = e.target.value.toLowerCase();
		if (searchText) {
			const filteredData =
				jargonBustersFields.jargonBustersBanner.selectJargonBusters.nodes.filter(
					(value) => {
						return value.jargonBustersPostFields.jargonBusterHeading
							.toLowerCase()
							.includes(searchText);
					}
				);
			setJargonBusters(filteredData);
		} else {
			setJargonBusters(
				jargonBustersFields.jargonBustersBanner.selectJargonBusters.nodes
			);
		}
	};

	const CustomToggle = ({ children, eventKey, handleClick }) => {
		const decoratedOnClick = useAccordionButton(eventKey, () => {
			handleClick();
		});

		return (
			<div
				className="card-header border-0 d-flex justify-content-between align-items-center px-4 py-2"
				type="button"
				onClick={decoratedOnClick}
			>
				{children}
			</div>
		);
	};

	const breadcrumb = {
		"@context": "http://schema.org",
		"@type": "BreadcrumbList",
		itemListElement: [
			{
				"@type": "ListItem",
				position: "1",
				name: "Home",
				item: {
					url: `${siteUrl}`,
					id: `${siteUrl}`,
				},
			},
			{
				"@type": "ListItem",
				position: "2",
				name: "Jargon Busters",
				item: {
					url: `${siteUrl}/jargon-busters`,
					id: `${siteUrl}/jargon-busters`,
				},
			},
		],
	};

	return (
		<>
			<Helmet>
				<script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
			</Helmet>
			<GatsbySeo
				title={seoFields?.title}
				description={seoFields?.metaDescription}
				language="en"
				openGraph={{
					type: "website",
					url: `${siteUrl}/jargon-busters`,
					title: `${seoFields?.opengraphTitle}`,
					description: `${seoFields?.opengraphDescription}`,
					images: [
						{
							url: `${seoFields?.image?.node.sourceUrl}`,
							width: `${seoFields?.image?.node.mediaDetails.width}`,
							height: `${seoFields?.image?.node.mediaDetails.height}`,
							alt: `${seoFields?.image?.node.altText}`,
						},
					],
				}}
			/>

			<Layout isDefault={false}>
				<section style={sectionStyle()} className="py-5 py-md-8">
					<Container>
						<Row className="justify-content-center">
							<Col lg={6} className="mb-4 mb-lg-0 text-center">
								<h2 className="my-auto text-white pb-4">
									{jargonBustersFields.jargonBustersBanner.jargonBustersHeading}
								</h2>
								<div
									dangerouslySetInnerHTML={{
										__html:
											jargonBustersFields.jargonBustersBanner
												.jargonBustersContent,
									}}
									className="fw-light text-white text-center"
								/>
								<Form>
									<Row className="my-4 justify-content-center">
										<Col xs={10} md={8}>
											<Form.Control
												type="text"
												placeholder="Search"
												className="bg-lighter-grey"
												onChange={handleSearchChange}
												style={{ padding: "12px 16px" }}
											/>
										</Col>
									</Row>
								</Form>
								{jargonBusters.length === 0 && (
									<p className="text-white pt-5">No results found.</p>
								)}
							</Col>
						</Row>
						<Accordion activeKey={activeKey} className="row my-5 my-md-7">
							{jargonBusters.map((item, index) => (
								<Col lg={6} className="mb-4">
									<Card key={index} className="bg-transparent border-white">
										<CustomToggle
											as={Card.Header}
											eventKey={index}
											handleClick={() => {
												if (activeKey === index) {
													setActiveKey(null);
												} else {
													setActiveKey(index);
												}
											}}
										>
											<h6 className="text-white m-0">
												{item.jargonBustersPostFields.jargonBusterHeading}
											</h6>
											<span className="text-white fs-2 montserrat-regular">
												{activeKey === index ? "-" : "+"}
											</span>
										</CustomToggle>
										<Accordion.Collapse eventKey={index}>
											<Card.Body className="border-top border-white">
												<div
													dangerouslySetInnerHTML={{
														__html:
															item.jargonBustersPostFields.jargonBusterContent,
													}}
													className="fw-light text-white text-start"
												></div>
											</Card.Body>
										</Accordion.Collapse>
									</Card>
								</Col>
							))}
						</Accordion>
					</Container>
				</section>
				<CtaBanner
					heading={
						jargonBustersFields?.jargonBustersCta.jargonBustersCtaHeading
					}
					content={
						jargonBustersFields?.jargonBustersCta.jargonBustersCtaContent
					}
					btnCta1={jargonBustersFields?.jargonBustersCta.jargonBustersCtaButton}
					bgImage={
						jargonBustersFields?.jargonBustersCta
							.jargonBustersCtaBackgroundImage?.node
					}
					textPosition="center"
					isGreen={true}
				/>
			</Layout>
		</>
	);
};

export const sectionStyle = (bgImage) => ({
	background: `linear-gradient(0deg, rgba(63, 122, 97, 0.95) 0%, rgba(63, 122, 97, 0.95) 100%), url(${bgImage}) lightgray 50% / cover no-repeat`,
});

export default JargonBusterPage;
